<template>
   <router-link 
   :to="'/order/' + order.id"
   class="order-item radius-20 border overflow-hidden shadow-sm mb-3 pt-2 pb-3 px-3">

      <div class="row">
         <div class="col-6 mb-1 py-2">
            Ordine <b>#{{ order.id }}</b>
         </div>
         <div class="col-6 mb-1 text-end py-2">
            {{ format_date(order.date_created) }}
         </div>
         <div class="col-6 m-0">
            <div :class="'p-2 radius-20 text-center ' + get_order_status(order.status).alertClass" role="alert">
               <small>{{ get_order_status(order.status).label }} {{ getLezioni() }}</small>
            </div>
         </div>
         <div class="col-6 m-0 text-end">
            <div class="py-2">
               {{ formatPrice(order.total) }}
            </div>
         </div>
      </div>

   </router-link>
</template>

<script scoped>
import { Utils } from '@/inc/Utils';

export default {
   name: 'OrdersItem',
   props: ['order'],
   mixins: [Utils],
   methods: {
      getLezioni(){
         var lezioni_totali = this.getMeta('lezioni-totali');
         var lezioni_effettuate = this.getMeta('lezioni-effettuate') ? this.getMeta('lezioni-effettuate') : 0;
         
         if( lezioni_totali ){
            return lezioni_effettuate+'/'+lezioni_totali;
         }
      },
      getMeta( key ) {
         if( ! this.$props.order.meta_data.length ){ return false; }
         var meta_value = false;
         this.$props.order.meta_data.forEach( (item) => {
            if( item.key == key ){
               meta_value = item.value
            }
         });
         return meta_value;
      },
   }
}
</script>

<style scoped>
.order-item {
   display: block;
   color: inherit;
   text-decoration: none;
}
</style>