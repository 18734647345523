<template>
   <vue-tel-input 
      :id="id" 
      v-bind="$attrs"
      v-bind:value="value"
      v-on="inputListeners"

      defaultCountry="IT"
      :inputOptions="inputOptions"
      :dropdownOptions="dropdownOptions"
      :mode="mode"

   ></vue-tel-input>
</template>

<script>
import { Utils } from "@/inc/Utils";

const VueTelInput = () =>
   Promise.all([
      import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
      import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input/dist/vue-tel-input.css'),
   ]).then(([{ VueTelInput }]) => VueTelInput);

export default {
   name: 'inputTel',
   mixins: [Utils],
   components: {
      VueTelInput
   },

   model: {
      prop: 'value',
      event: 'input'
   },

   props: {
      type: {
         type: String,
         default: 'tel'
      },
      id: {
         type: String,
         default: 'phone'
      },
      name: {
         type: String,
         default: 'phone'
      },
      value: {
         type: String,
         default: ''
      },
      placeholder: {
         type: String,
         default: ''
      },
      styleClasses: {
         type: String,
         default: ''
      },
      required: {
         type: Boolean,
         default: false
      },
      readonly: {
         type: Boolean,
         default: false
      },
      mode: {
         type: String,
         default: 'international'
      },
   },
   
   data(){
      return {
         dropdownOptions: {
            disabled: false,
            showDialCodeInList: true,
            showDialCodeInSelection: false,
            showFlags: true,
            showSearchBox: false,
            tabindex: 0,
            width: 'calc(100vw - 40px)',
         },
         inputOptions: {
            autocomplete: 'off',
            autofocus: false,
            'aria-describedby': this.__('Telefono'),
            id: this.id ? this.id : '',
            maxlength: 25,
            name: this.name ? this.name : 'telefono',
            showDialCode: false,
            placeholder: this.placeholder ? this.placeholder : this.__('Telefono'),
            readonly: this.readonly,
            required: this.required,
            tabindex: 0,
            type: this.type ? this.type : 'tel',
            styleClasses: this.styleClasses
         }
      }
   },

   methods: {

   },

   computed: {
      inputListeners: function () {
         var vm = this
         // `Object.assign` merges objects together to form a new object
         return Object.assign( {},
            // We add all the listeners from the parent
            this.$listeners,
            // Then we can add custom listeners or override the
            // behavior of some listeners.
            {
               // This ensures that the component works with v-model
               input: function (event) {
                  vm.$emit('input', event)
               }
            }
         )
      }
   }
   
}
</script>
<style>
.vue-tel-input {
  border-color: gainsboro;
  border-radius: 10px;
}
.vti__input{
  padding: 10px 15px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.vti__dropdown{
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.vti__dropdown-list {
   border-color: gainsboro!important;
   /* width: calc(100vw - 40px)!important; */
   border-radius: 10px;
}
.vti__dropdown-item strong {
   font-weight: 600;
   margin-left: 7px;
}
</style>