<template>

   <main id="account-fatturazione-screen" class="main">

      <div class="breadcrumb">
         <div class="text">{{__('Account')}} <span class="slash">/</span> {{__('Dettagli')}}</div>
      </div>


      <div class="container">

         <div v-if="user.ID">

            <h1 class="page-title">{{__('Dati di fatturazione')}}</h1>

            <!-- form data -->
            <form class="account-form" @submit.prevent="save_data()">
               <!-- first_name -->
               <div class="mb-3">
                  <label for="first_name" class="form-label">{{__('Nome')}} *</label>
                  <input required v-model="formData.billing.first_name" type="text" placeholder="Mario" id="first_name" class="form-input" />
               </div>
               <!-- last_name -->
               <div class="mb-3">
                  <label for="last_name" class="form-label">{{__('Cognome')}} *</label>
                  <input required v-model="formData.billing.last_name" type="text" placeholder="Rossi" id="last_name" class="form-input" />
               </div>
               <!-- address_2 -->
               <div class="mb-3">
                  <label for="company" class="form-label">{{__('Società')}}</label>
                  <input v-model="formData.billing.company" type="text" placeholder="Società" id="company" class="form-input" />
               </div>
               <!-- address_1 -->
               <div class="mb-3">
                  <label for="address_1" class="form-label">{{__('Indirizzo')}} *</label>
                  <input required v-model="formData.billing.address_1" type="text" placeholder="Indirizzo" id="address_1" class="form-input" />
               </div>
               <!-- address_2 -->
               <div class="mb-3">
                  <label for="address_2" class="form-label">{{__('Interno')}}</label>
                  <input v-model="formData.billing.address_2" type="text" placeholder="Appartamento, suite, ecc." id="address_2" class="form-input" />
               </div>

               <!-- countries, states -->
               <div v-if="Object.keys(countries).length">
                  <!-- country -->
                  <div class="mb-3">
                     <label for="country" class="form-label">{{__('Paese')}} *</label>
                     <select required v-model="formData.billing.country" id="country" class="form-select">
                        <option value="">{{__('-- Seleziona --')}}</option>
                        <option v-for="(country, key) in countries" :key="key" :value="key">
                           {{country.name}}
                        </option>
                     </select>

                  </div>
                  <!-- state -->
                  <div class="mb-3" v-if="formData.billing.country && countries[formData.billing.country] && countries[formData.billing.country].states && Object.keys(countries[formData.billing.country].states).length">
                     <label for="state" class="form-label">{{__('Provincia')}} *</label>
                     <select required v-model="formData.billing.state" id="state" class="form-select">
                        <option value="">{{__('-- Seleziona --')}}</option>
                        <option v-for="(state, key) in countries[formData.billing.country].states" :key="key" :value="key">
                           {{state}}
                        </option>
                     </select>
                  </div>
               </div>
               
               <div v-else class="text-center pt-5 mb-5">
                  {{__("Errore nel caricamento dei dati. Controlla la tua connessione e riavvia l'app.")}}
               </div>
               
               <!-- city -->
               <div class="mb-3">
                  <label for="city" class="form-label">{{__('Città')}} *</label>
                  <input required v-model="formData.billing.city" type="text" placeholder="Città" id="city" class="form-input" />
               </div>
               <!-- postcode -->
               <div class="mb-3">
                  <label for="postcode" class="form-label">{{__('CAP')}} *</label>
                  <input required v-model="formData.billing.postcode" type="text" placeholder="CAP" id="postcode" class="form-input" />
               </div>
               <!-- email -->
               <div class="mb-3">
                  <label for="email" class="form-label">{{(__('Email'))}} *</label>
                  <input required v-model="formData.billing.email" type="text" placeholder="Email" id="email" class="form-input" />
               </div>
               <!-- phone -->
               <div class="mb-3">
                  <label for="phone" class="form-label">{{__('Telefono')}} *</label>
                  <inputTel id="phone" v-model="formData.billing.phone" :placeholder="__('Telefono')" :required="true" />
               </div>

               <button class="btn btn-primary w-100" type="submit">
                  
                  <span v-if="form_saved">{{saveButtonText}}</span>

                  <span v-else>
                     <span class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">{{__('Caricamento...')}}</span>
                     </span>
                  </span>
               </button>

            </form>

         </div>

         <!-- spinner -->
         <div class="d-flex align-items-center justify-content-center py-3" v-else>
            <i class="fa fa-spinner rotating"></i>
         </div>

      </div>

   </main>   
</template>

<script scoped>
import { Utils } from '@/inc/Utils';
import { mapActions, mapGetters } from "vuex";
import inputTel from '@/components/inputTel';

export default{
   name: 'Fatturazione',
   components: {
      inputTel
   },
   mixins: [Utils],

   data(){
      return {
         form_saved: true,
         saveButtonText: 'Salva',
         formData: {
            billing: {
               address_1: "",
               address_2: "",
               city: "",
               company: "",
               country: "",
               email: "",
               first_name: "",
               last_name: "",
               phone: "",
               postcode: "",
               state: "",
            }
         }
      }
   },

   computed: {
      ...mapGetters("account", ["user"]),
      ...mapGetters("product", ["countries"]),
   },
   
   methods: {
      ...mapActions("account", ["get_user","save_customer_data"]),
      ...mapActions("product", ["getCountries"]),

      save_data(){
         this.form_saved = false;

         this.save_customer_data(this.formData).then( (res) => {
            
            if( res.errors ){
               this.errors = res.errors;
            }

            this.form_saved = true;
            this.saveButtonText = this.__('Salvato!')
                        
            setTimeout(()=>{
               this.saveButtonText = this.__('Salva');
               window.scrollTo(0,0)
               
            }, 1200)
         })
      },

      loadCountryState(code){
         
         if( ! code.length ){ return []; }

         return this.countries.filter((el)=>{
            return ( el.code == code );
         })[0].states;
      },

      load_data(){
         const vm = this;
         Object.keys(vm.formData.billing).forEach((key)=>{
            vm.formData.billing[key] = vm.user.billing[key]
         });
      }
   },
   
   created(){

      var vm = this;
      if( ! this.user.ID ){
         this.get_user().then( ()=>{
            vm.load_data();
         });
      }else{
         vm.load_data();
      }

      this.getCountries();
   },
}
</script>

<style scoped>
</style>