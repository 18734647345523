<template>

   <component :is="screen_name"></component>
   
</template>

<script scoped>
import Info from '@/store/account/components/Info.vue'
import Ordini from '@/store/account/components/Ordini.vue'
import Fatturazione from '@/store/account/components/Fatturazione.vue'
import { Utils } from '@/inc/Utils'

export default{
   name: 'AccountDetails',
   mixins: [Utils],
   components: {
      Info,
      Ordini,
      Fatturazione
   },
   data(){
      return {
         screen_name : ''
      }
   },

   created(){
      switch( this.$route.params.screen ){         
         default:
            this.screen_name = this.capitalize(this.$route.params.screen);
            break;
      }
   }
}
</script>

<style scoped>
</style>