<template>
   <div class="orders-list">

      <div v-for="order in orders" :key="order.id">
         <OrderItem :order="order" />
      </div>

   </div>
</template>

<script scoped>
import OrderItem from './OrderItem.vue';

export default {
   name: 'OrdersList',
   props: ['orders'],
   components: {
      OrderItem
   }
}
</script>

<style scoped>

</style>