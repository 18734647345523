<template>

   <main id="account-ordini-screen" class="main">

      <div class="breadcrumb">
         <div class="text">{{__('Account')}} <span class="slash">/</span> {{__('Dettagli')}}</div>
      </div>

      <div class="container">

         <div v-if="user.orders.length">
            <OrdersList :orders="user.orders" />
         </div>

         <div class="d-flex align-items-center justify-content-center py-3" v-else>
            {{__('Nessun ordine presente')}}
         </div>

      </div>

   </main>

</template>

<script scoped>
import { mapActions, mapGetters } from "vuex";
import { Utils } from '@/inc/Utils';
import OrdersList from '@/components/OrdersList.vue';

export default{
   name: 'Ordini',
   mixins: [Utils],
   components: {
      OrdersList
   },

   data(){
      return {
      }
   },
   
   methods: {
      ...mapActions("account", ["get_user"]),
   },
   
   computed: {
      ...mapGetters("account", ["user"])
   },

   created(){
      
      if( ! this.user.ID ){
         this.get_user();
      }
   }
   
}
</script>

<style scoped>
</style>