<template>

   <main id="account-details-screen" class="main">

      <div class="breadcrumb">
         <div class="text">{{__('Account')}} <span class="slash">/</span> {{__('Dettagli')}}</div>
      </div>

      <div class="container">

         <div v-if="user.ID">
            
            <h1 class="page-title">{{__('Dati Account')}}</h1>

            <!-- form data -->

            <form class="account-form" @submit.prevent="save_data(formData)" v-if="user.ID">
               <!-- errors -->
               <Errors :errors="errors" />

               <!-- first name -->
               <div class="mb-3">
                  <label for="first_name" class="form-label">{{__('Nome *')}}</label>
                  <input required v-model="formData.meta.first_name" type="text" placeholder="Mario" id="first_name" class="form-input" />
               </div>
               <!-- last name -->
               <div class="mb-5">
                  <label for="last_name" class="form-label">{{__('Cognome *')}}</label>
                  <input required v-model="formData.meta.last_name" type="text" placeholder="Rossi" id="last_name" class="form-input" />
               </div>

               <!-- password -->
               <div class="mb-3">
                  <div v-html="__('Inserisci <em>Password</em> e <em>Conferma password</em> per modificare.')"></div>
               </div>

               <div class="mb-3">
                  <label for="password" class="form-label">{{__('Password')}}</label>
                  <inputPassword id="password" v-model="formData.password" :placeholder="__('Password')" :required="true" />
               </div>
               <!-- confirm_password -->
               <div class="mb-3">
                  <label for="confirm_password" class="form-label">{{__('Conferma password')}}</label>
                  <inputPassword id="confirm_password" v-model="formData.confirm_password" :placeholder="__('Conferma password')" :required="true" />
               </div>

               <button class="btn btn-primary w-100" type="submit">
                  
                  <span v-if="form_saved">{{saveButtonText}}</span>

                  <span v-else>
                     <span class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">{{__('Caricamento...')}}</span>
                     </span>
                  </span>
               </button>

            </form>
         </div>

         <!-- spinner -->

         <div class="d-flex align-items-center justify-content-center py-3" v-else>
            <i class="fa fa-spinner rotating"></i>
         </div>
         
      </div>
   </main>
   
</template>

<script scoped>
import { mapActions, mapGetters } from "vuex";
import { Utils } from '@/inc/Utils';
import Errors from '@/components/Errors.vue';
import inputPassword from '@/components/inputPassword.vue';

export default{
   name: 'Info',
   mixins: [Utils],
   components: {
      Errors,
      inputPassword
   },
   
   data(){
      return {
         errors: [],
         form_saved: true,
         saveButtonText: 'Salva',
         formData: {
            meta: {
               first_name : '',
               last_name : '',
            },
            password: '',
            confirm_password: '',
         }
      }
   },
   computed: {
      ...mapGetters("account", ["user"])
   },
   
   methods: {
      ...mapActions("account", ["save_user_data","get_user"]),

      save_data(){

         this.errors = [];
         this.form_saved = false;
         
         if( this.formData.password ){
            this.errors = this.errors.concat( this.validPassword(this.formData.password) )
            
            if( this.formData.password != this.formData.confirm_password ){
               this.errors.push({ text: this.__('Le password non corrispondono')});
            }
            
         }

         if (this.errors.length) {
            this.form_saved = true;
            this.saveButtonText = this.__('Salva');
            window.scrollTo(0,0)
            return false;
         }
         
         this.save_user_data(this.formData).then( (res) => {
            
            if( res.errors ){
               this.errors = res.errors;
            }

            this.form_saved = true;
            this.saveButtonText = this.__('Salvato!')
                        
            setTimeout(()=>{
               this.saveButtonText = this.__('Salva');
               window.scrollTo(0,0)
               this.formData.password = '';
               this.formData.confirm_password = '';
               
            }, 1200)
         })
      },

      load_data(){
         this.formData.meta.first_name = this.user.meta.first_name;
         this.formData.meta.last_name = this.user.meta.last_name;
      }
   },

   created(){
      
      var vm = this;
      if( ! this.user.ID ){
         this.get_user().then( ()=>{
            vm.load_data();
         });
      }else{
         vm.load_data();
      }
   }
}
</script>

<style scoped>
</style>