<!-- password-input.vue -->
<template>
   <div class="input-group password">
      <input 
         :type="type" 
         :id="id" 
         :name="name" 
         :placeholder="placeholder" 
         :class="styleClasses" 
         :required="required" 
         :readonly="readonly" 
         v-bind:value="value"
         v-on:input="$emit( 'input', $event.target.value )" />
         <span class="input-group-text" @click="switchType">
            <i class="fa-regular fa-eye" v-if="hidden"></i>
            <i class="fa-regular fa-eye-slash" v-else></i>
         </span>
   </div>
</template>

<script>
   import { Utils } from "@/inc/Utils";

   export default {
      name: 'inputPassword',
      mixins: [Utils],
      model: {
         prop: 'value',
         event: 'input'
      },
      props: {
         type: {
            type: String,
            default: 'password'
         },
         id: {
            type: String,
            default: 'password'
         },
         name: {
            type: String,
            default: 'password'
         },
         value: {
            type: String,
            default: ''
         },
         placeholder: {
            type: String,
            default: 'Password'
         },
         styleClasses: {
            type: String,
            default: 'form-control'
         },
         required: {
            type: Boolean,
            default: true
         },
         readonly: {
            type: Boolean,
            default: false
         },
      },

      data(){
         return {
            input :null,
            hidden: true
         }
      },

      methods: {
         switchType(){
            this.hidden = ! this.hidden;
            this.type = (this.type == 'text') ? 'password' : 'text';
         }
      }

   };
</script>
<style scoped>
input {
   border: none
}
.input-group{
   border-radius: 10px;
   overflow: hidden;
   border: 1px solid gainsboro;
}
.input-group-text {
   background: transparent;
   cursor: pointer;
   border: none;
}
i.fa-eye-slash {
    position: relative;
    right: -1px;
}
</style>